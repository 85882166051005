import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import IconLien from "../assets/icon/link.svg"
//component
import Menu from "../components/Menu"
const Tree = ({ data, initState, fiche }) => {
  //buttonState
  const [buttonState, setButtonState] = useState(initState)
  //changeState
  const [changeState, setChangeState] = useState(0)
  //ciblage
  const [ciblage, setCiblage] = useState(0)
  // focus
  const [focus, setFocus] = useState("")

  let newState = buttonState
  // changeOneState
  const changeOneState = (id, name, active) => {
    newState = buttonState
    buttonState.map((item, index) => {
      if (item.id === id) {
        if (active) {
          newState[index].active = active
        } else {
          newState[index].active = !newState[index].active
        }
      }
      return true
    })
    setChangeState(changeState => 1)
  }

  // changeAllStateFalse
  const changeAllStateFalse = () => {
    setFocus(focus => "")
    setCiblage(ciblage => 0)

    newState = buttonState
    buttonState.map((item, index) => {
      newState[index].active = false

      return true
    })
    setChangeState(changeState => 1)
  }

  // changeAllStateTrue
  const changeAllStateTrue = () => {
    changeAllStateFalse()
    newState = buttonState
    buttonState.map((item, index) => {
      newState[index].active = true

      return true
    })
    setChangeState(changeState => 1)
  }

  // loopLignage
  const loopLignage = dataLignage => {
    let id = dataLignage.id
    let name = dataLignage.name
    let active = true
    changeOneState(id, name, active)

    if (
      fiche.nodes.filter(i => i.id === dataLignage.id)[0].oldestfiche === null
    ) {
      let nextId = fiche.nodes
        .filter(i => i.id === dataLignage.id)[0]
        .component.filter(i => i.fiche[0].name !== name)
        .map(i => i.fiche[0].id)[0]

      let newData = fiche.nodes.filter(i => i.id === nextId)[0]
      loopLignage(newData)
    }
  }

  //scrollTo

  //clearSearch
  const clearSearch = () => {
    setFilteredStates(filteredStates => fiche.nodes)
    setSearch(search => "")
  }
  // searchClick
  const searchClick = id => {
    changeAllStateFalse()
    clearSearch()
    setCiblage(ciblage => 1)
    setFocus(focus => id)
    newState = buttonState
    loopLignage(fiche.nodes.filter(i => i.id === id)[0])
  }
  //search
  const [search, setSearch] = useState("")
  const [filteredStates, setFilteredStates] = useState(fiche.nodes)

  useEffect(() => {
    //change buttonState
    if (changeState) {
      setChangeState(changeState => 0)
      setButtonState(buttonState => newState)
    }

    //search filter
    const filter = fiche.nodes.filter(state => {
      return state.name.toLowerCase().includes(search.toLowerCase())
    })
    setFilteredStates(filter)
  }, [
    buttonState,
    setChangeState,
    changeState,
    newState,
    search,
    fiche.nodes,
    initState,
  ])

  // create family
  const family = dataFamily => {
    let conjoint = []
    return (
      <>
        {dataFamily.map((item, index) => {
          return (
            <div className="family" key={index}>
              <div
                className={`${item.gender[0] === "homme" ? "boy" : "girl"} ${
                  ciblage
                    ? buttonState
                        .filter(i => i.id === item.id)
                        .map(j => j.active)[0]
                      ? "highlight"
                      : ""
                    : ""
                } ${focus === item.id ? "focus" : ""}`}
              >
                {item.name}
                <Link to={`/fiche/${item.id}`} className="lienFiche">
                  <IconLien className="iconLien" />
                </Link>
              </div>
              {item.component
                ? item.component.filter(i => i.name[0] === "mariage").length > 0
                  ? item.component
                      .filter(i => i.name[0] === "mariage")
                      .map(i =>
                        i.reference.map((item, index) => {
                          if (data.filter(c => c.id === item.id)) {
                            conjoint.push(data.filter(c => c.id === item.id)[0])
                            return (
                              <div className={`conjoint`} key={index}>
                                {conjoint
                                  .filter(i => i.id === item.id)
                                  .map((i, j) =>
                                    i.gender[0] === "homme" ? (
                                      <div
                                        className={`boy ${
                                          ciblage
                                            ? buttonState
                                                .filter(i => i.id === item.id)
                                                .map(j => j.active)[0]
                                              ? "highlight"
                                              : ""
                                            : ""
                                        } ${focus === item.id ? "focus" : ""}`}
                                        key={j}
                                      >
                                        {item.name}
                                        <Link
                                          to={`/fiche/${item.id}`}
                                          className="lienFiche"
                                        >
                                          <IconLien className="iconLien" />
                                        </Link>
                                      </div>
                                    ) : (
                                      <button
                                        className={`girl ${
                                          ciblage
                                            ? buttonState
                                                .filter(i => i.id === item.id)
                                                .map(j => j.active)[0]
                                              ? "highlight"
                                              : ""
                                            : ""
                                        } ${focus === item.id ? "focus" : ""}`}
                                        key={j}
                                        onClick={() =>
                                          changeOneState(item.id, item.name)
                                        }
                                      >
                                        {conjoint
                                          .filter(i => i.id === item.id)
                                          .map(i => i.component)[0]
                                          .filter(i => i.name[0] === "enfant")
                                          .length > 0 ? (
                                          <span>
                                            {buttonState
                                              .filter(i => i.id === item.id)
                                              .map(j => j.active)[0]
                                              ? "-"
                                              : "+"}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        {item.name}
                                        <Link
                                          to={`/fiche/${item.id}`}
                                          className="lienFiche"
                                        >
                                          <IconLien className="iconLien" />
                                        </Link>
                                      </button>
                                    )
                                  )}
                                {conjoint.length
                                  ? conjoint.map(i =>
                                      i.component
                                        ? i.component.filter(
                                            i => i.name[0] === "enfant"
                                          )
                                          ? i.component
                                              .filter(
                                                i => i.name[0] === "enfant"
                                              )
                                              .map(i =>
                                                i.reference.map((i, index) => {
                                                  if (
                                                    data.filter(
                                                      c => c.id === i.id
                                                    )
                                                  ) {
                                                    return (
                                                      <div
                                                        className={`enfant child-${buttonState
                                                          .filter(
                                                            t =>
                                                              t.id === item.id
                                                          )
                                                          .map(j => j.active)}`}
                                                        key={index}
                                                      >
                                                        {family(
                                                          data.filter(
                                                            c => c.id === i.id
                                                          )
                                                        )}
                                                      </div>
                                                    )
                                                  } else {
                                                    return false
                                                  }
                                                })
                                              )
                                          : ""
                                        : ""
                                    )
                                  : ""}
                              </div>
                            )
                          } else {
                            return null
                          }
                        })
                      )
                  : ""
                : ""}
              {item.component
                ? item.component.filter(i => i.name[0] === "conjoint").length >
                  0
                  ? item.component
                      .filter(i => i.name[0] === "conjoint")
                      .map(i =>
                        i.reference.map((item, index) => {
                          if (data.filter(c => c.id === item.id)) {
                            conjoint.push(data.filter(c => c.id === item.id)[0])
                            return (
                              <div className={`conjoint`} key={index}>
                                {conjoint
                                  .filter(i => i.id === item.id)
                                  .map((i, j) =>
                                    i.gender[0] === "homme" ? (
                                      <div
                                        className={`boy ${
                                          ciblage
                                            ? buttonState
                                                .filter(i => i.id === item.id)
                                                .map(j => j.active)[0]
                                              ? "highlight"
                                              : ""
                                            : ""
                                        } ${focus === item.id ? "focus" : ""}`}
                                        key={j}
                                      >
                                        {item.name}
                                        <Link
                                          to={`/fiche/${item.id}`}
                                          className="lienFiche"
                                        >
                                          <IconLien className="iconLien" />
                                        </Link>
                                      </div>
                                    ) : (
                                      <button
                                        className={`girl ${
                                          ciblage
                                            ? buttonState
                                                .filter(i => i.id === item.id)
                                                .map(j => j.active)[0]
                                              ? "highlight"
                                              : ""
                                            : ""
                                        } ${focus === item.id ? "focus" : ""}`}
                                        key={j}
                                        onClick={() =>
                                          changeOneState(item.id, item.name)
                                        }
                                      >
                                        {conjoint
                                          .filter(i => i.id === item.id)
                                          .map(i => i.component)[0]
                                          .filter(i => i.name[0] === "enfant")
                                          .length > 0 ? (
                                          <span>
                                            {buttonState
                                              .filter(i => i.id === item.id)
                                              .map(j => j.active)[0]
                                              ? "-"
                                              : "+"}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        {item.name}
                                        <Link
                                          to={`/fiche/${item.id}`}
                                          className="lienFiche"
                                        >
                                          <IconLien className="iconLien" />
                                        </Link>
                                      </button>
                                    )
                                  )}
                                {conjoint.length
                                  ? conjoint.map(i =>
                                      i.component
                                        ? i.component.filter(
                                            i => i.name[0] === "enfant"
                                          )
                                          ? i.component
                                              .filter(
                                                i => i.name[0] === "enfant"
                                              )
                                              .map(i =>
                                                i.reference.map((i, index) => {
                                                  if (
                                                    data.filter(
                                                      c => c.id === i.id
                                                    )
                                                  ) {
                                                    return (
                                                      <div
                                                        className={`enfant child-${buttonState
                                                          .filter(
                                                            t =>
                                                              t.id === item.id
                                                          )
                                                          .map(j => j.active)}`}
                                                        key={index}
                                                      >
                                                        {family(
                                                          data.filter(
                                                            c => c.id === i.id
                                                          )
                                                        )}
                                                      </div>
                                                    )
                                                  } else {
                                                    return false
                                                  }
                                                })
                                              )
                                          : ""
                                        : ""
                                    )
                                  : ""}
                              </div>
                            )
                          } else {
                            return null
                          }
                        })
                      )
                  : ""
                : ""}
            </div>
          )
        })}
      </>
    )
  }
  return (
    <div>
      <div className="navTree">
        <fieldset>
          <h2>Navigation</h2>
          <Menu />
        </fieldset>
        <fieldset>
          <h2>Deployement</h2>
          <button onClick={() => changeAllStateTrue()}>Deploy All</button>
          <button onClick={() => changeAllStateFalse()}>Reset </button>
        </fieldset>
        <fieldset>
          <h2>Search</h2>
          <input value={search} onChange={e => setSearch(e.target.value)} />
          <div className={`search ${search.length > 1 ? "" : "hidden"}`}>
            {filteredStates &&
              filteredStates.map(state => {
                return (
                  <button
                    key={state.id}
                    onClick={() => searchClick(state.id, state.name)}
                  >
                    {state.name}
                  </button>
                )
              })}
          </div>
        </fieldset>
      </div>
      <h1>Famille Dinaux</h1>
      {family(data.filter(i => i.oldestfiche !== null))}
    </div>
  )
}

export default Tree
