import React from "react"
import { graphql, useStaticQuery } from "gatsby"

//component
import Tree from "../components/Tree"
//css
import "../css/nav.scss"
import "../css/tree.scss"
//data
const getData = graphql`
  {
    a: allContentfulFiche {
      nodes {
        id
        gender
        name
        notDinaux
        oldestfiche {
          id
        }
        component {
          name
          reference {
            id
            name
          }
        }
      }
    }
    b: allContentfulFiche {
      nodes {
        name
        id
        oldestfiche {
          id
        }
        notDinaux
        component {
          name
          fiche {
            name
            id
          }
        }
      }
    }
  }
`
const Index = () => {
  const data = useStaticQuery(getData)

  //table for buttonState
  let iniTable = data.b.nodes.map(item => item)

  let initState = []

  iniTable.map(item => {
    let id = item.id
    let name = item.name
    let active = false

    initState.push({ id, name, active })
    return true
  })

  return (
    <div className="tree">
      {<Tree data={data.a.nodes} fiche={data.b} initState={initState} />}
    </div>
  )
}

export default Index
